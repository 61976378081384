<template>
  <TabMenu
    :model="items"
    :active-index="0"
  />
  <div
    class="pb-5"
  >
    <Card>
      <template #content>
        <router-view />
      </template>
    </Card>
    <Toast />
  </div>
</template>

<script>

export default {
  props: {
    // eslint-disable-next-line vue/no-unused-properties
    selectedProfile: {
      type: Object,
      required: false,
      default: null,
    },
  },
  emits: ['clear-selected-profile', 'change-menu-layout'],
  data() {
    return {
      items: [
        {
          label: 'Accounts',
          icon: 'pi pi-fw pi-users',
          to: { name: 'adminDashoard', query: this.query },

        },
        {
          label: 'Discounts',
          icon: 'pi pi-fw pi-percentage',
          to: { name: 'adminDiscounts', query: this.query },

        },
        {
          label: 'Invitations',
          icon: 'pi pi-fw pi-envelope',
          to: { name: 'adminInvitations', query: this.query },

        },
      ],
    };
  },
};
</script>
